import { forwardRef } from 'react'
import Info from './info'
import countriesData from '../../../json/countries.json'

// Create a functional component using forwardRef
const CountriesInfo = forwardRef((props, ref) => {
    return (
        <>
            {/* Loop through the countriesData array */}
            {countriesData.map((country, i) => (
                // Render the Info component for each country
                <Info 
                    ref={element => ref.current[country.id] = element} 
                    key={i} 
                    props={country}
                />
            ))}
        </>
    )
})

export default CountriesInfo
