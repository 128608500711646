import {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'
import './index.css'
import './fonts.css'
import reportWebVitals from './reportWebVitals'
import App from './app'

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <App/>
  </StrictMode>
)

reportWebVitals()