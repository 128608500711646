// Import the Logo component from a relative path and the Link component from 'react-router-dom'
import Logo from '../../logo-enveseur.svg'
import { Link } from 'react-router-dom'

// Define the CookiePolicy component
const CookiePolicy = () => {
    // Function to get the current year
    const getCurrentYear = () => new Date().getFullYear()

    return (
        <>
            {/* Container for the cookie policy page */}
            <div className='cp-lp-container'>
                {/* Header section */}
                <header className='cp-lp-header'>
                    {/* Navigation */}
                    <nav>
                        <ul>
                            <li>
                                {/* Link to the homepage */}
                                <Link to='/'>
                                    <img width="400" height="200" src={Logo} alt="Enveseur" />
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </header>
                {/* Main content section */}
                <main className='cp-lp-main'>
                    {/* Cookie policy section */}
                    <section className='cp-lp-section'>
                        <h1 className='cp-lp-h1'>
                            Cookie policy
                        </h1>
                        <p>
                            {/* Description of the cookie policy */}
                            Our website uses cookies. By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.
                        </p>
                    </section>
                    {/* About cookies section */}
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            About cookies
                        </h2>
                        <p>
                            {/* Explanation of what cookies are */}
                            A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser, and stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.
                        </p>
                        <p>
                            {/* More information about the use of cookies */}
                            Cookies can be used by web servers to identify and track users as they navigate different pages on a website and to identify users returning to a website.
                        </p>
                        <p>
                            {/* Explanation of persistent and session cookies */}
                            Cookies may be either 'persistent' cookies or 'session' cookies. A persistent cookie consists of a text file sent by a web server to a web browser, which will be stored by the browser and will remain valid until its set expiry date (unless deleted by the user before the expiry date). A session cookie, on the other hand, will expire at the end of the user session when the web browser is closed.
                        </p>
                    </section>
                    {/* Cookies on this website section */}
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            Cookies on this website
                        </h2>
                        <p>
                            {/* Description of the types of cookies used */}
                            We use both session cookies and persistent cookies on this website.
                        </p>
                        <p>
                            {/* List of specific cookies used */}
                            We may send to you the following cookies:
                        </p>
                        <ul>
                            <li>
                                (1) __utm*, _ga (Google Analytics)
                            </li>
                            <li>
                                (2) cookie (Custom cookie to remember cookie user choice)
                            </li>
                        </ul>
                    </section>
                    {/* How we use cookies section */}
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            How we use cookies
                        </h2>
                        <p>
                            {/* Explanation of how cookies are used */}
                            Cookies do not contain any information that personally identifies you, but personal information that we store about you may be linked, by us, to the information stored in and obtained from cookies.
                        </p>
                        <p>
                            {/* List of purposes for using cookies */}
                            We may use the information we obtain from your use of our cookies for the following purposes:
                        </p>
                        <ul>
                            <li>
                                to recognise your computer when you visit our website;
                            </li>
                            <li>
                                to track you as you navigate our website and to enable the use of the shopping cart on our website;
                            </li>
                            <li>
                                to improve the website's usability;
                            </li>
                            <li>
                                to analyse the use of our website;
                            </li>
                            <li>
                                to prevent fraud and improve the security of the website;
                            </li>
                            <li>
                                to personalise our website for you.
                            </li>
                        </ul>
                    </section>
                    {/* Third-party cookies section */}
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            Third-party cookies
                        </h2>
                        <p>
                            {/* Information about third-party cookies */}
                            When you use our website, you may also be sent third-party cookies.
                        </p>
                        <p>
                            {/* Information about Google Analytics */}
                            In addition, we use Google Analytics to analyse the use of this website. Google Analytics generates statistical and other information about website use by means of cookies, which are stored on users' computers. The information generated relating to our website is used to create reports about the use of the website. Google will store this information. Google's privacy policy is available at: <a href="http://www.google.com/privacypolicy.html" target='_blank' rel='noreferrer'>http://www.google.com/privacypolicy.html</a>
                        </p>
                    </section>
                    {/* Blocking cookies section */}
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            Blocking cookies
                        </h2>
                        <p>
                            {/* Instructions for blocking cookies in different browsers */}
                            Most browsers allow you to refuse to accept cookies. For example:
                        </p>
                        <ul>
                            <li>
                                {/* Instructions for blocking cookies in Internet Explorer */}
                                in Internet Explorer, you can refuse all cookies by clicking 'Tools', 'Internet Options', 'Privacy', and selecting 'Block all cookies' using the sliding selector;
                            </li>
                            <li>
                                {/* Instructions for blocking cookies in Firefox */}
                                In FireFox you can block all cookies by clicking on 'Menu', select 'Settings', choose 'Privacy & Security', under the 'Privacy' section, select 'Custom' privacy settings. Im the 'Cookies' options, choose 'All cookies'.            
                            </li>
                        </ul>
                        <p>
                            {/* Note about the impact of blocking all cookies */}
                            However, blocking all cookies will have a negative impact upon the usability of many websites.
                        </p>
                    </section>
                    {/* Deleting cookies section */}
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            Deleting cookies
                        </h2>
                        <p>
                            {/* Instructions for deleting cookies in different browsers */}
                            You can also delete cookies already stored on your computer:
                        </p>
                        <ul>
                            <li>
                                {/* Instructions for deleting cookies in Internet Explorer */}
                                In Internet Explorer, you must manually delete cookie files (you can find instructions for doing so at http://support.microsoft.com/kb/278835);
                            </li>
                            <li>
                                {/* Instructions for deleting cookies in Firefox */}
                                In Firefox you can delete cookies by clicking on "Menu", select "Settings", choose the "Privacy and security" panel, in the "Cookies and site data" section, click on the "Clear data..." button and click on the "Clear" button.
                            </li>
                        </ul>
                        <p>
                            {/* Note about the impact of deleting cookies */}
                            Doing this may have a negative impact on the usability of many websites.
                        </p>
                    </section>
                    {/* Contact information section */}
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            Contact us
                        </h2>
                        <p>
                            {/* Information about the website's ownership */}
                            This website is owned and operated by Enveseur S.L.
                        </p>
                        <p>
                            {/* Contact information */}
                            If you have any questions about our cookies or this cookies policy, please contact us by email to <a href="mailto:admin@enveseur.com">admin@enveseur.com</a>, by telephone to  <a href="tel:+34911875521">+34 911 87 55 21</a> or by post.
                        </p>
                    </section>
                </main>
            </div>
            {/* Footer section */}
            <footer className='cp-lp-footer footer-bg'>
                <div className='cp-lp-footer-div'>
                    <div className='cp-lp-footer-div-div'>
                        {/* Footer links */}
                        <ul className='cp-lp-footer-ul-first'>
                            <li>
                                {/* Copyright information */}
                                Copyright © {getCurrentYear()} Enveseur SL
                            </li>
                            <li>
                                {/* Company address */}
                                <address>Carrer Avinyonet, 6<br />Vilafranca del Penedès, 08720<br />Barcelona, España<br />B66856618</address>
                            </li>
                        </ul>
                        <ul className='cp-lp-footer-ul'>
                            <li>
                                {/* Contact email */}
                                <a href="mailto:admin@enveseur.com">
                                    admin@enveseur.com
                                </a>
                            </li>
                            <li>
                                {/* Contact telephone */}
                                <a href="tel:+34935220222">
                                    +34 935 22 02 22
                                </a>
                            </li>
                        </ul>
                        <ul className='cp-lp-footer-ul'>
                            <li>
                                {/* Link to the Cookie Policy page */}
                                <Link to='/cookie-policy'>
                                    Cookie policy
                                </Link>
                            </li>
                            <li>
                                {/* Link to the Legal Note page */}
                                <Link to='/legal-note'>
                                    Legal note
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

// Export the CookiePolicy component as the default export
export default CookiePolicy
