import InfoMobile from "./info"
import countriesData from '../../../json/countries.json'

// Create a functional component
const CountriesInfoMobile = () => {
    return (
        <>
            {/* Loop through the countriesData array */}
            {countriesData.map((country, i) => (
                // Render the Info component for each country
                <InfoMobile 
                    key={i} 
                    props={country}
                />
            ))}
        </>
    )
}

export default CountriesInfoMobile
