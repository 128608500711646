import Logo from '../../logo-enveseur.svg'
import { Link } from 'react-router-dom'

const LegalNote = () => {
    // Function to get the current year
    const getCurrentYear = () => new Date().getFullYear()

    return (
        <>
            <div className='cp-lp-container'>
                <header className='cp-lp-header'>
                    <nav>
                        <ul>
                            <li>
                                {/* Link to homepage with Enveseur logo */}
                                <Link to='/'>
                                    <img width="400" height="200" src={Logo} alt="Enveseur" />
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </header>
                <main className='cp-lp-main'>
                    <section className='cp-lp-section'>
                        <h1 className='cp-lp-h1'>
                            Legal note
                        </h1>
                        <p>
                            Our website uses cookies. By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.
                        </p>
                    </section>
                    <section className='cp-lp-section'>
                        <h2 className='cp-lp-h2'>
                            Website terms and conditions
                        </h2>
                        <p>
                            WELCOME TO THE ENVESEUR S.L. WEBSITE TERMS AND CONDITIONS FOR USE. THESE TERMS AND CONDITIONS APPLY TO THE USE OF THIS WEBSITE AT www.enveseur.com. BY ACCESSING THIS WEBSITE AND/OR PLACING AN ORDER, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.
                        </p>
                        <p>
                            USING THIS WEBSITE INDICATES THAT YOU ACCEPT THESE TERMS REGARDLESS OF WHETHER OR NOT YOU CHOOSE TO REGISTER WITH US OR ORDER FROM US. IF YOU DO NOT ACCEPT THESE TERMS, DO NOT USE THIS WEBSITE.
                        </p>
                        <p>
                            The www.enveseur.com website is operated by:
                        </p>
                        <p>
                            Enveseur S.L., a company registered in Spain, whose registered office is at Carrer Avinyonet 6, Vilafranca del Penedès, 08720, Barcelona, Spain
                        </p>
                        <p>
                            Company Registered in Spain No. B66856618.
                        </p>
                        <p>
                            Our contact details are as follows:
                        </p>
                        <p>
                            Trading Address:
                        </p>
                        <p>
                            Enveseur S.L. |
                            Carrer Avinyonet 6
                            Vilafranca del Penedès
                            08720 Barcelona, Spain
                        </p>
                        <p>
                            General email: <a href="mailto:admin@enveseur.com">admin@enveseur.com</a>
                        </p>
                        <p>
                            Telephone number: <a href="tel:++34669392628">+34 669 392 628</a>
                        </p>
                    </section>
                    {/* Additional sections and content */}
                </main>
            </div>
            <footer className='cp-lp-footer footer-bg'>
                <div className='cp-lp-footer-div'>
                    <div className='cp-lp-footer-div-div'>
                        <ul className='cp-lp-footer-ul-first'>
                            <li>
                                {/* Copyright and company details */}
                                Copyright © {getCurrentYear()} Enveseur S.L.
                            </li>
                            <li>
                                <address>Carrer Avinyonet, 6<br />Vilafranca del Penedès, 08720<br />Barcelona, Spain<br />B66856618</address>
                            </li>
                        </ul>
                        <ul className='cp-lp-footer-ul'>
                            <li>
                                {/* Email and phone contact */}
                                <a href="mailto:admin@enveseur.com">
                                    admin@enveseur.com
                                </a>
                            </li>
                            <li>
                                <a href="tel:+34935220222">
                                    +34 935 22 02 22
                                </a>
                            </li>
                        </ul>
                        <ul className='cp-lp-footer-ul'>
                            <li>
                                {/* Links to other pages */}
                                <Link to='/cookie-policy'>
                                    Cookie policy
                                </Link>
                            </li>
                            <li>
                                <Link to='/legal-note'>
                                    Legal note
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default LegalNote
