// Import the Link component from 'react-router-dom'
import { Link } from 'react-router-dom'

// Define the Page404 component as a functional component
const Page404 = () => (
  <>
    <div className="p-5 grid items-center justify-center h-screen w-full">
      <div className="grid gap-12">
        {/* Display the error message */}
        <h1 className="text-xl md:text-5xl text-center">
          ERROR 404 - PAGE NOT FOUND
        </h1>
        {/* Create a navigation section */}
        <nav>
          <ul>
            <li className="text-center md:text-2xl hover:underline">
              {/* Create a Link to the home page */}
              <Link className="text-blue-800 hover:text-blue-500" to="/">
                Home page
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </>
)

// Export the Page404 component as the default export
export default Page404